<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-tabs v-model="activeTabIndex">
          <b-tab title="Activity Log" active>
            <ActivityLog v-if="activeTabIndex === 0"/>
          </b-tab>
          <b-tab title="Billing">
            <BillingHistory v-if="activeTabIndex === 1"/>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityLog from './ActivityLog'
import BillingHistory from './BillingHistory'

export default {
  components: { 
    ActivityLog, BillingHistory
  },

  data() {
    return {
      activeTabIndex: 0,
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  },
}
</script>
<template>
  <div class="report-records-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-4">
              <div class="col-md-6">
                <label class="field-label">Date Range</label>
                <date-range-picker
                  ref="picker"
                  v-model="filter.dateRange"
                  :opens="filter.opens"
                  :locale-data="{ firstDay: 1, format: 'mmm dd, yyyy' }"
                  :min-date="filter.minDate"
                  :max-date="filter.maxDate"
                  :single-date-picker="filter.singleDatePicker"
                  :time-picker="filter.timePicker"
                  :time-picker24-hour="filter.timePicker24Hour"
                  :show-week-numbers="filter.showWeekNumbers"
                  :show-dropdowns="filter.showDropdowns"
                  :auto-apply="filter.autoApply"
                  :always-show-calendars="filter.alwaysShowCalendars"
                  :linked-calendars="filter.linkedCalendars"
                  :ranges="filter.ranges"
                  @update="filterDateUpdateValues"
                >
                </date-range-picker>
              </div>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getRecords"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-select
                      v-model="datatable.queries.business_id"
                      class="form-control form-control-sm ml-2"
                    >
                      <b-form-select-option v-for="business in businesses" :key="business.id" :value="business.id" >{{ business.name }}</b-form-select-option>
                    </b-form-select>
                    <b-form-select
                      v-model="datatable.queries.search_type"
                      class="form-control form-control-sm ml-2"
                    >
                      <b-form-select-option v-for="item in searchTypes" :key="item.id" :value="item.id" >{{ item.name }}</b-form-select-option>
                    </b-form-select>
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="onQueryChange('search')"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="refresh"
                  >
                    <i class="uil uil-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loading">
              <div class="table-responsive mb-0">
                <b-table
                  :items="records.data"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | moment('MM-DD-YYYY hh:mm:ss A') }}
                  </template>
                  <template v-slot:cell(cost)="data">
                    {{ data.item.cost ? `$${data.item.cost.toFixed(4)}` : '-' }}
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalRecords }} records
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalRecords"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment-timezone'

export default {
  components: { 
    DateRangePicker
  },
  data() {
    return {
      datatable: {
        columns: [
          { key: 'business_name', label: 'Business', sortable: false },
          { key: 'from', label: 'Business',  sortable: false },
          { key: 'direction_str', label: 'Direction', sortable: false },
          { key: 'type_str', label: 'Type', sortable: false },
          { key: 'carrier_str', label: 'Carrier', sortable: false },
          { key: 'to', label: 'Contact',  sortable: false },
          { key: 'length', label: 'Length (Duration)', sortable: false },
          { key: 'cost', sortable: false },
          { key: 'created_at', sortable: false },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          search_type: null,
          sort_by: 'id',
          sort_desc: true,
          business_id: null,
          start_at: null,
          end_at: null,
        },
      },
      records: [],
      loading: false,
      businesses: [],
      searchTypes: [
        {id: 0, name: 'All'},
        {id: 1, name: 'SMS'},
        {id: 2, name: 'Voice'},
        {id: 3, name: 'Carrier Fee'},
        {id: 4, name: 'Number Provisioning'},
      ],
      filter : {
        opens: 'right',
        minDate: null,
        maxDate: null,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        dateRange: {
          startDate: moment().subtract(30, 'days').toDate(),
          endDate: moment().toDate(),
        },
        linkedCalendars: true,
        ranges: {
          'Today': [moment().toDate(), moment().toDate()],
          'Last 7 Days': [moment().subtract(7, 'days').toDate(), moment().toDate()],
          'Last 30 Days': [moment().subtract(30, 'days').toDate(), moment().toDate()],
          'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
        },
        alwaysShowCalendars: true,
      }
    }
  },

  computed: {
    totalRecords() {
      return this.records && this.records.meta ? this.records.meta.total : 0
    }
  },

  mounted() {
    this.datatable.queries.search_type = 0
    this.filterDateUpdateValues()
    this.getBusinessOptions()
  },

  methods: {
    filterDateUpdateValues(range) {
      this.datatable.queries.start_at = moment(this.filter.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      this.datatable.queries.end_at = moment(this.filter.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      this.getRecords();
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getRecords()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getRecords()
    },

    onQueryChange(type = undefined) {
      this.$nextTick(() => {
        if (type === 'search')
        {
          this.datatable.queries.page = 1;
        }
        this.getRecords()
      });
    },

    getBusinessOptions() {
      this.$store.dispatch('business/getBusinessOptions')
        .then((businesses) => {
          this.businesses = [
            { name: 'All businesses', id: null },
            ...businesses.map(b => {
              return {
                name: b.name,
                id: b.id,
              }
            })
          ]
          
        })
        .catch(() => {
        })
    },

    getRecords() {
      this.loading = true
      this.records = [];

      this.$store.dispatch('report/getAll', this.datatable.queries)
        .then((res) => {
          this.records = res
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    refresh() {
      this.datatable.queries.search = null
      this.datatable.queries.search_type = 0
      this.datatable.queries.business_id = null
      this.getRecords();
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  width: 150px;
  height: calc( 1.5em + 0.5rem + 2px );
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-width: 200px;
}
</style>